@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Work+Sans:wght@400;700&display=swap');
@import '@arcgis/core/assets/esri/themes/light/main.css';

.esri-popup__main-container {
  max-width: 800px !important;
}

* {
  outline: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: inherit;
}

body {
  font-family: Roboto, sans-serif;
}

ul,
ol {
  list-style: none;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.MuiMenu-list {
  max-height: 400px;
}

.react-date-picker__calendar {
  z-index: 10 !important;
}
.RaSidebar-drawerPaper-40 {
  width: 250px !important;
}
